import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgChartsAngularModule } from 'ag-charts-angular';

import { MaterialsModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SnackBarModule } from '@schaeffler/snackbar';
import { ErrorComponent } from './layout/error/error.component';
import { HeaderModule } from './layout/header/header.module';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { IconsModule } from './layout/icons/icons.module';

// ag-grid
import { TableComponent } from '../kpi-dashboard/main-table/table/table.component';
import { ThroughtputTimePopupTableComponent } from '../statistic/throughtput-time/popup-chart-table/table/throughtput-time-popup-table.component';

import { AnalysisRendererComponent } from '../kpi-dashboard/main-table/renderer-component/analysis-renderer.component';
import { HistoryRendererComponent } from '../kpi-dashboard/main-table/renderer-component/history-renderer.component';
import { FavoriteRendererComponent } from '../kpi-dashboard/main-table/renderer-component/favorite-renderer.component';
// import { DetailCellRendererComponent } from '../kpi-dashboard/main-table/renderer-component/detail-cell-renderer.component';
import { AggregationLevelChartComponent } from '../kpi-dashboard/header-control/aggregation-level/chart/chart.component';
// import { AnalysisRendererAgilityPartComponent }
// from '../kpi-dashboard/main-table/renderer-component/analysis-renderer-agility-part.component';

const declarations = [
  ErrorComponent,
  SpinnerComponent,

  TableComponent,
  AnalysisRendererComponent,
  // AnalysisRendererAgilityPartComponent,
  HistoryRendererComponent,
  FavoriteRendererComponent,
  // DetailCellRendererComponent,
  AggregationLevelChartComponent,
  ThroughtputTimePopupTableComponent,
];

const modules = [CommonModule, HttpClientModule, ReactiveFormsModule, MaterialsModule, SnackBarModule, HeaderModule, IconsModule];

@NgModule({
  declarations: [...declarations],
  imports: [
    ...modules,
    TranslateModule.forChild({}),
    RouterModule,

    AgGridModule.withComponents(
      [
        AnalysisRendererComponent,
        // AnalysisRendererAgilityPartComponent,
        HistoryRendererComponent,
        FavoriteRendererComponent,
        // DetailCellRendererComponent,
        AggregationLevelChartComponent,
      ]
    ),
    AgChartsAngularModule,
  ],
  exports: [...declarations, ...modules, TranslateModule],
  providers: [],
})
export class SharedModule { }
