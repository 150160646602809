// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://throughputtime-d.dev.dp.schaeffler',
    helpLink: 'https://sconnect.schaeffler.com/login.jspa?referer=%252Fgroups%252Fthroughput-time-analysis-tool',
    tenantId: '67416604-6509-4014-9859-45e709f53d3f',
    clientId: 'cac3487d-5786-46c4-9ac7-b5292be19ee1',
    appId: 'api://b5ee2b46-5668-4058-98fd-193a276553e0/Access.Default',
    versionfe: '1.2.latest-6',
    backflushingLink: 'https://sconnect.schaeffler.com/docs/DOC-350866#jive_content_id_31_What_do_these_percentages_mean',
  };

  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
